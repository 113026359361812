const ID_TOKEN_KEY = "id_token";
const ID_TOKEN_TYPE = "id_token_type";
const ID_TOKEN_EXPIRE_TIME = "id_token_expire_time";
const ID_USER_ID = "id_user_id";
const ID_USER_TYPE = "id_user_type";
const ID_USER_NAME = "id_user_name";
const ID_USER_LAST_NAME = "id_user_last_name";
const ID_USER_PHOTO = "id_user_photo";
const ID_STUDENT_CLASS_ID = "id_student_class_id";
const ID_EMAIL_SET_NEEDED = "id_email_set_needed";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getTokenType = () => {
  return window.localStorage.getItem(ID_TOKEN_TYPE);
};

export const getUserId = () => {
  return window.localStorage.getItem(ID_USER_ID);
};

export const getUserType = () => {
  return window.localStorage.getItem(ID_USER_TYPE);
};

export const getUserName = () => {
  return window.localStorage.getItem(ID_USER_NAME);
};

export const getUserLastName = () => {
  return window.localStorage.getItem(ID_USER_LAST_NAME);
};

export const getUserPhoto = () => {
  return window.localStorage.getItem(ID_USER_PHOTO);
};

export const getUserEmailSetStatus = () => {
  return window.localStorage.getItem(ID_EMAIL_SET_NEEDED);
};

export const setUserPhoto = photo => {
  return window.localStorage.setItem(ID_USER_PHOTO, photo);
};

export const getStudentClassId = () => {
  return window.localStorage.getItem(ID_STUDENT_CLASS_ID);
};

export const saveToken = (
  token,
  type,
  expire_time,
  user_id,
  user_type,
  user_name,
  user_last_name,
  student_class_id,
  email_set_needed
) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_TOKEN_TYPE, type);
  window.localStorage.setItem(ID_TOKEN_EXPIRE_TIME, expire_time);
  window.localStorage.setItem(ID_USER_ID, user_id);
  window.localStorage.setItem(ID_USER_TYPE, user_type);
  window.localStorage.setItem(ID_USER_NAME, user_name);
  window.localStorage.setItem(ID_USER_LAST_NAME, user_last_name);
  window.localStorage.setItem(ID_STUDENT_CLASS_ID, student_class_id);
  window.localStorage.setItem(ID_EMAIL_SET_NEEDED, email_set_needed);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_TYPE);
  window.localStorage.removeItem(ID_TOKEN_EXPIRE_TIME);
  window.localStorage.removeItem(ID_USER_ID);
  window.localStorage.removeItem(ID_USER_TYPE);
  window.localStorage.removeItem(ID_USER_NAME);
  window.localStorage.removeItem(ID_USER_LAST_NAME);
  window.localStorage.removeItem(ID_USER_PHOTO);
  window.localStorage.removeItem(ID_STUDENT_CLASS_ID);
  window.localStorage.removeItem(ID_EMAIL_SET_NEEDED);
};

export const changeUserEmailSetStatus = () => {
  return window.localStorage.setItem(ID_EMAIL_SET_NEEDED, "false");
};

export default {
  getToken,
  getTokenType,
  getUserId,
  getUserType,
  getUserName,
  getUserLastName,
  getUserPhoto,
  getUserEmailSetStatus,
  changeUserEmailSetStatus,
  setUserPhoto,
  saveToken,
  destroyToken,
  getStudentClassId
};
