import Vue from "vue";
import Router from "vue-router";
import { getUserType } from "@/common/jwt.service";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue")
        },
        {
          path: "/chat",
          name: "chat",
          component: () => import("@/views/pages/chat/Chat.vue")
        },
        {
          path: "/lecturer/assigned-classes",
          name: "lecturerAssignedClasses",
          component: () => import("@/views/pages/lecturer/AssignedClasses.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/uploaded-materials",
          name: "lecturerUploadedMaterials",
          component: () =>
            import("@/views/pages/lecturer/UploadedMaterials.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/upload-new-class-materials/class/:id",
          name: "lecturerUploadNewClassMaterials",
          component: () =>
            import("@/views/pages/lecturer/UploadNewMaterial.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/upload-new-assignment/:id",
          name: "uploadNewAssignment",
          component: () =>
            import("@/views/pages/lecturer/UploadNewAssignment.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/uploaded-assignment",
          name: "uploadedAssignment",
          component: () =>
            import("@/views/pages/lecturer/UploadedAssignments.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path:
            "/lecturer/assignment-evaluation-and-grading/:id/:lecturer_class_subject_id",
          name: "assignmentEvaluationGrading",
          component: () =>
            import("@/views/pages/lecturer/AssignmentEvaluationAndGrading.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/class-attendance/:id",
          name: "classAttendance",
          component: () => import("@/views/pages/lecturer/ClassAttendance.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/class-materials",
          name: "studentClassMaterials",
          component: () => import("@/views/pages/student/ClassMaterials.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/profile",
          name: "studentProfile",
          component: () => import("@/views/pages/student/StudentProfile.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/profile",
          name: "lecturerProfile",
          component: () => import("@/views/pages/lecturer/LecturerProfile.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/upload-new-quiz/:id",
          name: "uploadNewQuiz",
          component: () => import("@/views/pages/lecturer/UploadNewQuiz.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/uploaded-quiz",
          name: "uploadedQuiz",
          component: () => import("@/views/pages/lecturer/UploadedQuiz.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path:
            "/lecturer/quiz-evaluation-and-grading/:id/:lecturer_class_subject_id",
          name: "quizEvaluationGrading",
          component: () =>
            import("@/views/pages/lecturer/QuizEvaluationAndGrading.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/exam-results",
          name: "studentExamResults",
          component: () => import("@/views/pages/student/ExamResults.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/payment-history",
          name: "studentPaymentHistory",
          component: () => import("@/views/pages/student/PaymentHistory.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/assignment",
          name: "studentAssignment",
          component: () => import("@/views/pages/student/Assignment.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/subject-assignment-list/:class_id/:subject_id",
          name: "subjectAssignmentList",
          component: () =>
            import("@/views/pages/student/SubjectAssignmentList.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/quiz",
          name: "studentQuiz",
          component: () => import("@/views/pages/student/Quiz.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/subject-quiz-list/:class_id/:subject_id",
          name: "subjectQuizList",
          component: () => import("@/views/pages/student/SubjectQuizList.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/student-feedbacks",
          name: "studentFeedbacks",
          component: () => import("@/views/pages/student/StudentFeedbacks.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/student/exam-complaints",
          name: "handlingExamComplaints",
          component: () =>
            import("@/views/pages/student/HandlingExamComplaints.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "student") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/set-your-email",
          name: "setEmail",
          component: () => import("@/views/pages/SetEmail.vue")
        },
        {
          path: "/online-class",
          name: "onlineClass",
          component: () => import("@/views/pages/OnlineClass.vue")
        },
        {
          path: "/lecturer/class-quizzes-report/:id",
          name: "classQuizzesReport",
          component: () =>
            import("@/views/pages/lecturer/ClassQuizzesReport.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/class-assignments-report/:id",
          name: "classAssignmentsReport",
          component: () =>
            import("@/views/pages/lecturer/ClassAssignmentsReport.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/class-attendance-report/:id",
          name: "classAttendanceReport",
          component: () =>
            import("@/views/pages/lecturer/ClassAttendanceReport.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/lecturer/salaries",
          name: "lecturerSalaries",
          component: () => import("@/views/pages/lecturer/Salaries.vue"),
          beforeEnter: (to, from, next) => {
            if (getUserType() !== "lecturer") next({ name: "dashboard" });
            else next();
          }
        },
        {
          path: "/library/books",
          name: "libraryBooks",
          component: () => import("@/views/pages/library/LibraryBook.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register")
        },
        {
          path: "/reset-password",
          name: "reset-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            auth: false
          }
        },
        {
          path: "/reset-password/:token",
          name: "reset-password-form",
          component: () => import("@/views/pages/ResetPasswordForm.vue"),
          meta: {
            auth: false
          }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ]
});
