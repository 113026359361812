import ApiService from "@/common/api.service";
import Pusher from "pusher-js";
import {
  getTokenType,
  getToken,
  getUserId,
  getUserType,
  getStudentClassId
} from "@/common/jwt.service";

// action types
export const LOAD_USERS = "loadUsers";
export const SEARCH_USERS = "searchUsers";
export const SELECT_USER = "selectUser";
export const SEND_MESSAGE = "sendMessage";
export const SUBSCRIBE_TO_CHANNEL = "subscribeToChannel";
export const SUBSCRIPTION_MESSAGE = "subscriptionMessage";
export const DELETE_MESSAGE = "deleteMessage";

// getter types
export const MESSAGES = "messages";
export const USERS_LIST = "usersList";

// mutation types
export const SET_USERS_LIST_LOADING_STATUS = "setUsersListLoadingStatus";
export const SET_CHAT_CONTENT_LOADING_STATUS = "setChatContentLoadingStatus";
export const SET_USERS_LIST = "setUsersList";
export const SET_FILTERED_USERS_LIST = "setFilteredUsersList";
export const SET_SELECTED_USER = "setSelectedUser";
export const SET_MESSAGES = "setMessages";
export const SET_SENT_MESSAGE = "setSentMessage";
export const SET_RECEIVED_MESSAGE = "setReceivedMessage";
export const SET_USER_UNREAD_MESSAGES = "setUserUnreadMessages";
export const SET_CAN_CHANGE_OPPONENT_USER = "setCanChangeOpponentUser";
export const SET_CHANNEL = "setUsersListLoadingStatus";
export const SET_USER_STATUS = "setUserStatus";
export const REMOVE_DELETED_MESSAGE = "removeDeletedMessage";

const state = {
  pusher: new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
    authEndpoint: process.env.VUE_APP_BROADCAST_AUTH_URL,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    auth: {
      headers: {
        Authorization: `${getTokenType()} ${getToken()}`
      }
    }
  }),
  channel: null,
  usersList: [],
  filteredUsersList: [],
  messages: [],
  selectedUser: {
    id: null,
    name: "",
    last_name: "",
    image: null,
    type: null,
    isOnline: null
  },
  isUsersListLoading: false,
  isChatContentLoading: false,
  canChangeOpponentUser: true
};

const getters = {
  [MESSAGES](state, user) {
    return state.messages.filter(item => item.user === user);
  },
  [USERS_LIST](state) {
    return state.usersList;
  }
};

const actions = {
  [LOAD_USERS](context) {
    return new Promise(resolve => {
      context.commit(SET_USERS_LIST_LOADING_STATUS, true);
      ApiService.get("chat/" + getUserType() + "/users-list").then(
        ({ data }) => {
          context.commit(SET_USERS_LIST, data);
          context.commit(SET_USERS_LIST_LOADING_STATUS, false);
          resolve(data);
        }
      );
    });
  },
  [SEARCH_USERS]({ commit, state }, keyword) {
    const filteredUsersList = state.usersList.filter(item => {
      return (
        item.name.toLowerCase().indexOf(keyword) >= 0 ||
        item.last_name.toLowerCase().indexOf(keyword) >= 0 ||
        item.type.toLowerCase().indexOf(keyword) >= 0
      );
    });
    commit(SET_FILTERED_USERS_LIST, filteredUsersList);
  },
  [SELECT_USER]({ commit, state }, user_id) {
    return new Promise(resolve => {
      commit(SET_CHAT_CONTENT_LOADING_STATUS, true);
      ApiService.get("chat/messages/" + user_id).then(({ data }) => {
        commit(SET_SELECTED_USER, user_id);
        commit(SET_MESSAGES, data.messages);
        commit(SET_CHAT_CONTENT_LOADING_STATUS, false);
        if (state.messages.length > 0) {
          const lastMessage = state.messages[state.messages.length - 1];
          if (lastMessage.status === "0") {
            const params = {
              user_id: user_id,
              message_id: lastMessage.id
            };
            ApiService.post("chat/mark-as-read", params);
            commit(SET_USER_UNREAD_MESSAGES, { user_id: user_id, counter: 0 });
          }
        }
        resolve(data);
      });
    });
  },
  [SEND_MESSAGE](context, payload) {
    context.commit(SET_CAN_CHANGE_OPPONENT_USER, false);
    return new Promise(resolve => {
      ApiService.post("chat/send-message", payload).then(({ data }) => {
        context.commit(SET_CAN_CHANGE_OPPONENT_USER, true);
        let commitPayload = {
          message: payload.message,
          data: data
        };
        context.commit(SET_SENT_MESSAGE, commitPayload);
        resolve(data);
      });
    });
  },
  [SUBSCRIBE_TO_CHANNEL]({ commit, state }) {
    let channel = state.pusher.subscribe(`private-messages.${getUserId()}`);
    commit(SET_CHANNEL, channel);

    //setting online users
    let presenceChannel = state.pusher.subscribe(
      `presence-online.${getStudentClassId()}`
    );
    presenceChannel.bind("pusher:subscription_succeeded", function(members) {
      members.each(function(member) {
        commit(SET_USER_STATUS, { member: member, status: 1 });
      });
    });
    presenceChannel.bind("pusher:member_added", function(member) {
      commit(SET_USER_STATUS, { member: member, status: 1 });
    });
    presenceChannel.bind("pusher:member_removed", function(member) {
      commit(SET_USER_STATUS, { member: member, status: 0 });
    });
  },
  [SUBSCRIPTION_MESSAGE]({ commit, state }, message) {
    return new Promise(resolve => {
      if (
        parseInt(message.sender_user_id) === parseInt(state.selectedUser.id)
      ) {
        commit(SET_RECEIVED_MESSAGE, message);
      } else {
        let counter =
          state.usersList[
            state.usersList.findIndex(user => {
              return parseInt(user.id) === parseInt(message.sender_user_id);
            })
          ].unread_messages;
        commit(SET_USER_UNREAD_MESSAGES, {
          user_id: message.sender_user_id,
          counter: parseInt(counter) + 1
        });
      }
      resolve(message);
    });
  },
  [DELETE_MESSAGE]({ commit }, message_id) {
    ApiService.delete(`chat/delete-message?message_id=${message_id}`).then(
      () => {
        commit(REMOVE_DELETED_MESSAGE, message_id);
      }
    );
  }
};

const mutations = {
  [SET_USERS_LIST_LOADING_STATUS](state, status) {
    state.isUsersListLoading = status;
  },
  [SET_CHAT_CONTENT_LOADING_STATUS](state, status) {
    state.isChatContentLoading = status;
  },
  [SET_CAN_CHANGE_OPPONENT_USER](state, status) {
    state.canChangeOpponentUser = status;
  },
  [SET_USERS_LIST](state, userList) {
    state.usersList = userList;
  },
  [SET_FILTERED_USERS_LIST](state, filteredUsersList) {
    state.filteredUsersList = filteredUsersList;
  },
  [SET_SELECTED_USER](state, user_id) {
    state.selectedUser = state.usersList.filter(item => item.id === user_id)[0];
  },
  [SET_MESSAGES](state, messages) {
    state.messages = messages;
  },
  [SET_SENT_MESSAGE](state, { message, data }) {
    state.messages.push({
      id: data.id,
      type: "sent",
      message: message,
      status: 0,
      time: data.time
    });
  },
  [SET_RECEIVED_MESSAGE](state, message) {
    state.messages.push({
      id: message.id,
      type: "received",
      message: message.message,
      status: 0,
      time: message.time
    });
  },
  [SET_USER_UNREAD_MESSAGES](state, { user_id, counter }) {
    const userItemIndex = state.usersList.findIndex(user => {
      return user.id === user_id;
    });
    state.usersList[userItemIndex].unread_messages = counter;
  },
  [SET_CHANNEL](state, channel) {
    state.channel = channel;
  },
  [SET_USER_STATUS](state, { member, status }) {
    const userItemIndex = state.usersList.findIndex(user => {
      return parseInt(user.id) === parseInt(member.id);
    });
    if (state.usersList[userItemIndex] !== undefined)
      state.usersList[userItemIndex].isOnline = status;
  },
  [REMOVE_DELETED_MESSAGE](state, message_id) {
    const messageItemIndex = state.messages.findIndex(message => {
      return parseInt(message.id) === parseInt(message_id);
    });
    state.messages.splice(messageItemIndex, 1);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
