// Afghanistan - Persian
export const locale = {
  TRANSLATOR: {
    SELECT: "زبان خود را انتخاب نمایید."
  },
  MENU: {
    NEW: "جدید",
    PAGES: "صفحات",
    FEATURES: "امکانات",
    APPS: "برنامه ها",
    DASHBOARD: "صفحه اصلی (داشبورد)",
    CHAT: "پیام ها",
    ASSIGNED_CLASSES: "صنوف اختصاصی",
    CLASS_MATERIALS_UPLOADED: "مواد درسی آپلود شده",
    CLASS_MATERIALS_UPLOAD: "آپلود مواد درسی جدید",
    ATTENDANCE: "حاضری",
    CLASS_MATERIALS: "مواد درسی",
    PROFILE: "پروفایل",
    EXAM_RESULTS: "نتایج امتحانات",
    PAYMENT_HISTORY: "تاریخچه فیس",
    HELMS_SYSTEM: "سیستم تدریس آنلاین HELMS",
    UPLOADED_ASSIGNMENT: "کارخانگی های آپلود شده",
    ASSIGNMENT: "کارخانگی",
    UPLOADED_QUIZ: "امتحان های آپلود شده",
    QUIZ: "امتحان",
    STUDENT_FEEDBACKS: "ثبت شکایات و پشنهادات",
    QUIZZES_REPORT: "راپور امتحانات آنلاین",
    ATTENDANCE_REPORT: "راپور حاضری",
    ASSIGNMENT_REPORT: "راپور کارخانکی های آنلاین",
    LECTURE_SALARIES: "معاشات",
    LIBRARY_BOOKS: "کتابخانه"
  },
  AUTH: {
    GENERAL: {
      OR: "یا",
      SUBMIT_BUTTON: "ارسال",
      NO_ACCOUNT: "حساب ندارید؟",
      SIGNUP_BUTTON: "ایجاد حساب",
      FORGOT_BUTTON: "رمز عبور را فراموش کرده اید",
      BACK_BUTTON: "عقب",
      PRIVACY: "حریم خصوصی",
      LEGAL: "مجاز",
      CONTACT: "در تماس شدن",
      LOGOUT: "خروج از سیستم"
    },
    LOGIN: {
      TITLE: "وارد سیستم شوید",
      BUTTON: "ورود به سیستم"
    },
    FORGOT: {
      TITLE: "رمز خویش را فراموش کرده اید؟",
      DESC:
        "برای تغییر رمز حساب خود ایمیل آدرس خویش را وارد نمایید تا رمز حساب شما مجدداْ تنظیم گردد.",
      RESET_DESC:
        "برای تغییر رمز حساب خود معلومات ذیل را وارد نمایید تا رمز حساب شما مجدداْ تنظیم گردد",
      SUCCESS: "رمز حساب شما موفقانه تنظیم گردید.",
      RESET_PASSWORD: "تغییر رمز حساب",
      RETURN_TO_LOGIN: "برگشت به صفحه ورود به سیستم!"
    },
    INPUT: {
      ID: "نمبر آیدی",
      PASSWORD: "رمز حساب",
      PASSWORD_CONFIRMATION: "تکرار رمز حساب"
    }
  },
  DASHBOARD: {
    LATEST_NOTICES: "آخرین اطلاعیه ها",
    ASSIGNMENT_QUIZ_NOTICES: "اطلاعیه های کارخانگی و امتحان",
    NUMBER_OF_SUBJECTS: "تعداد مضامین",
    CLASS_MATERIALS_UPLOADED: "مواد درسی آپلود شده",
    RESULTS_ANNOUNCED: "نتایج اعلان شده",
    NEW_MESSAGES: "پیام های جدید"
  },
  ASSIGNED_CLASSES: {
    NO_CLASS_ASSIGNED: "برای شما تا حال کدام صنف اختصاص داده نشده است."
  },
  CLASS_MATERIALS_UPLOADED: {
    TITLE: "برای سال تحصیلی {year} دوره {term}",
    GUIDE:
      "مواد درسی آپلود شده برای محصلین همیشه قابل دید و استفاده بوده و فقط 24 ساعت بعد از آپلود قابل حذف میباشد و بعد از آن قابل حذف نمیباشد. درصورتیکه خواهش حذف آنرا داشته باشید با مدیریت سافتویر هاوس در تماس شوید.",
    NO_MATERIALS_UPLOADED:
      "برای صنوف اختصاص داده شده/جستجو شده هیچ مواد درسی آپلود نگردیده است."
  },
  ATTENDANCE: {
    TITLE:
      'صنف "{class}" / مضمون "{subject}"  /  تایم "{time}"  /  سمستر "{semester}" /  پوهنځی "{faculty}"',
    GUIDE:
      "حاضری محصلین فقط برای 24 ساعت بعد از ثبت قابل تغییر می باشد و بعد از آن قابل تغییر نمیباشد. درصورتیکه خواهش تغییر آنرا داشته باشید با پوهنڅی مربوطه در تماس شوید.",
    ATTENDANCE_STATE: "حالت حاضری",
    NO_STUDENT_IN_CLASS: "محصلین در این صنف وجود ندارد",
    TOTAL_ATTENDANCE: "تعداد روز های حاضری",
    TOTAL_PRESENT: "تعداد روز های حاضر",
    TOTAL_ABSENT: "تعداد روز های غیرحاضر",
    DOWNLOAD_EXCEL: "دانلود در اکسل"
  },
  CLASS_MATERIALS_UPLOAD: {
    TITLE:
      'برای صنف "{class}" مضمون "{subject}" تایم "{time}" سمستر "{semester}"',
    SELECT_A_FILE: "یک فایل را انتخاب کنید یا آن را در اینجا رها کنید ...",
    DROP_FILE: "فایل را اینجا رها کنید ...",
    UPLOAD: "آپلود",
    PROCESSING: "در حال پردازش..."
  },
  LIBRARY: {
    NO_LIBRARY_BOOKS: "برای موارد جستجو شده هیچ کتابی آپلود نگردیده است.",
    BOOK_DETAIL: "معلومات کتاب انتخاب شده",
    BOOK_SHOW: "نمایش کتاب"
  },
  UPLOADED_ASSIGNMENT: {
    TITLE:
      'برای صنف "{class}" / مضمون "{subject}"  /  تایم "{time}"  /  سمستر "{semester}" /  پوهنځی "{faculty}"',
    GUIDE:
      "کارخانگی ها برا محصلین همیشه قابل دید و استفاده بوده و فقط 24 ساعت بعد از درج کارخانگی قابل حذف میباشد و بعد از آن قابل حذف نمیباشد. درصورتیکه خواهش حذف آنرا داشته باشید با مدیریت سافتویر هاوس پوهنتون در تماس شوید. محصلین میتوانند فقط الی تاریخ و ساعت تعیین شده جواب کارخانگی را برای استاد خویش ارسال نماید.",
    NO_ASSIGNMENT_FOR_SEARCHED_CLASS:
      "برای صنوف جستجو شده هیچ کارخانگی درج نگردیده است.",
    ASSIGNMENT_TITLE: "عنوان کارخانگی",
    MARK_FEEDBACK_FOR: 'درج نمره و ارزیابی برای "{student}"',
    SUBMIT: "ارسال",
    NOTE:
      "نوت: استاد محترم از 0 الی 100 برای محصل انتخاب شده در انجام این کارخانگی نمره دهید!"
  },
  ASSIGNMENT: {
    TITLE:
      'برای صنف "{class}" / مضمون "{subject}"  /  تایم "{time}"  /  سمستر "{semester}" /  پوهنځی "{faculty}"',
    ASSIGNMENT_EVALUATION_GRADING: "ارزیابی و نمره دهی کارخانگی",
    DUE_DATE: "تاریخ انقضاء",
    DUE_TIME: "ساعت انقضاء",
    OPEN_ASSIGNMENTS: "باز کردن کارخانگی ها",
    DO: "انجام دادن",
    TEACHER_FEEDBACK: "ارزیابی استاد",
    NUMBER: "کارخانګی شماره {number}",
    TOTAL_MARKS: "مجموع نمرات",
    AVERAGE_MARKS: "اوسط نمرات",
    OBTAINED_MARK: "نمره اخذ شده",
    NO_ASSIGNMENT: "برای صنف انتخاب شده، کارخانګی آنلاین وجود ندارد."
  },
  UPLOADED_QUIZ: {
    TITLE:
      'برای صنف "{class}" / مضمون "{subject}"  /  تایم "{time}"  /  سمستر "{semester}" /  پوهنځی "{faculty}"',
    GUIDE:
      "امتحان ها برا محصلین همیشه قابل دید می باشد و فقط 24 ساعت بعد از درج امتحان قابل حذف میباشد و بعد از آن قابل حذف نمیباشد. درصورتیکه خواهش حذف آنرا داشته باشید با مدیریت سافتویر هاوس پوهنتون در تماس شوید. محصلین متوانند فقط در تاریخ و ساعت تعیین شده امتحان را انجام داده و را برای استاد خویش ارسال نماید.",
    NO_QUIZ_FOR_SEARCHED_CLASS:
      "برای صنوف جستجو شده هیچ امتحان درج نگردیده است.",
    QUIZ_TITLE: "عنوان امتحان",
    MARK_FEEDBACK_FOR: 'درج نمره و ارزیابی برای "{student}"',
    NOTE:
      "نوت: استاد محترم از 0 الی 100 برای محصل انتخاب شده در انجام این امتحان نمره دهید!"
  },
  QUIZ: {
    TITLE:
      'برای صنف "{class}" / مضمون "{subject}"  /  تایم "{time}"  /  سمستر "{semester}" /  پوهنځی "{faculty}"',
    QUIZ_EVALUATION_GRADING: "ارزیابی و نمره دهی امتحان",
    DATE: "تاریخ امتحان",
    START_TIME: "ساعت شروع",
    END_TIME: "ساعت ختم",
    OPEN_QUIZ: "باز کردن امتحانات",
    SUBMISSION_FORM: "فورم ارسال جوابات امتحان",
    UPLOAD_ANSWER: "ارسال جوابات",
    INFO_FOR_SUBMISSION:
      "نوت: شما فقط یک بار اجازه ارسال فایل جوابات امتحان را دارید. پس لطف نموده فایل را با دقت کامل آماده و ارسال نماید. بعد از یک بار ارسال نمودن. فورم ارسال جوابات امتحان برای این امتحان قابل دید نمی باشد",
    SUBMISSION_MESSAGE:
      "برای اشتراک نمودن در این امتحان در تاریخ و ساعت معین شده مراجعه نماید",
    DUE_MESSAGE: "تاریخ و ساعت امتحان انقضاء گردیده.",
    NO_QUIZ: "برای صنف انتخاب شده، امتحان آنلاین وجود ندارد.",
    NUMBER: "امتحان شماره {number}",
    TOTAL_MARKS: "مجموع نمرات",
    AVERAGE_MARKS: "اوسط نمرات",
    OBTAINED_MARK: "نمره اخذ شده"
  },
  CLASS_MATERIALS: {
    NO_MATERIALS_UPLOADED: "برای صنف انتخاب شده مواد درسی آپلود نگردیده است.",
    UPLOAD_TIME: "زمان آپلود"
  },
  EXAM_RESULTS: {
    TOTAL: "مجموع",
    AVERAGE: "فیصدی",
    RESULT: "نتیجه",
    GRADE: "درجه",
    FIRST_CHANCE: "چانس اول",
    SECOND_CHANCE: "چانس دوم",
    THIRD_CHANCE: "چانس سوم",
    NO_RESULT: "نتایج درج نگردیده"
  },
  PAYMENT_HISTORY: {
    PAYMENT_DATE: "تاریخ تحویلی",
    FEE_TYPE: "نوعیت فیس",
    INSTALLMENT: "قسط",
    TOTAL_AMOUNT: "مقدار مجموعی",
    PAID_AMOUNT: "مقدار تحویل شده",
    DISCOUNT: "تخفیف",
    SOURCE: "منبع",
    REMAINING: "باقیات",
    NOT_PAID: "تحویل نگردیده"
  },
  STUDENT_FEEDBACKS: {
    TITLE: "فورم ثبت شکایات و پشنهادات",
    GUIDE:
      "محصل گرامی در  صورت هر نوع شکایت و یا پیشنهاد از فورم ذیل استفاده نموده نظر خویش را با اداره پوهنتون در میان بگذارید!",
    NOTE:
      "نوت: برای ثبت شکایت و یا پیشنهاد در قسمت اول نوعیت را انتخاب نموده و در قسمت عنوان نام دفتر (بخش)  و یا شخص را که درباره ان شکایت یا پیشنهاد دارید درج نماید و در قسمت توضیحات تفصیل شکایت یا پیشنهاد خویش را درج نماید.",
    SUCCESS_MESSAGE:
      "شکایت / پیشنهاد شما موفقانه ثبت گردید در زمینه رسیدگی صورت میگیرد!",
    FEEDBACK_TYPE: "نوعیت",
    COMPLAINTS: "شکایت",
    SUGGESTIONS: "پیشنهاد",
    OR: "و یا ؟"
  },
  HANDLING_EXAM_COMPLAINTS: {
    TITLE: "",
    RESULTS: "شکایات نتایج",
    NO_COMPLAINTS: "در سمستر انتخاب شده هیج شکایت از طرف شما ارسال نگردیده!",
    VIEW_RESULTS: "مشاهده نتایج",
    NO_RESPONSE: "منتظر پاسخ کمیته امتحانات!"
  },
  GENERAL: {
    HI: "سلام",
    BACK: "برگشت",
    PLEASE_WAIT: "لطفاً منتظر باشید...",
    FACULTY: "پوهنځی",
    ALL_FACULTIES: "تمام پوهنځی ها",
    ALL_CATEGORIES: "تمام دسته بندی ها",
    DEPARTMENT: "دیپارتمنت",
    SEMESTER: "سمستر",
    ALL_SEMESTERS: "تمام سمستر ها",
    CURRENT_SEMESTER: "سمستر فعلی",
    CLASS: "صنف",
    CLASS_NAME: "نام صنف",
    CLASS_TIME: "وقت درسی",
    ALL_CLASSES: "تمام صنوف",
    SUBJECT: "مضمون",
    ALL_SUBJECTS: "تمام مضامین",
    SUBJECTS: "مضامین",
    NUMBER_OF_STUDENTS: "تعداد محصلین",
    ACTIONS: "اقدامات",
    UPLOAD_NEW_FILE: "آپلود فایل درسی جدید",
    UPLOADED_FILES: "فایل های درسی آپلود شده",
    FOR_YEAR_TERM: "برای سال تحصیلی {year} دوره {term}",
    FILE_NAME: "نام فایل",
    FILE_TYPE: "نوعیت فایل",
    FILE_SIZE: "حجم فایل",
    SELECT_FILE: "انتخاب فایل",
    VIEW_FILE: "مشاهده فایل",
    VIEW_FILE_DESCRIPTION: "مشاهده تفصیلات فایل",
    DELETE_FILE: "حذف فایل",
    YOUTUBE_LINK: "لنک ویدیو از یوتیوب",
    DESCRIPTION: "توضیحات",
    REMARK: "ملاحظات",
    SEND_REMARK: "ارسال ملاحظات",
    CANCEL: "منصرف",
    LECTURER: "استاد",
    NUMBER: "شماره",
    SELECT_SEMESTER: "سمستر را انتخاب نماید:",
    DOWNLOAD: "دانلود فایل",
    DELETE_CONFIRM: "آیا شما مطمئن هستید تا مورد انتخاب شده حذف شود؟",
    DELETE_INFORMATION:
      "درصورت تایید حذف، مورد انتخاب شده از سیستم کاملن حذف گردیده و امکان بازیافت آن نمیباشد.",
    DELETED: "مورد انتخاب شده موفقانه حذف گردید.",
    DELETE_FAILURE:
      "فایل حذف نگردید. دلیل خطا احتمال سپری بودن بیشتر از ۲۴ ساعت از آپلود مورد بوده ویا مشکل تخنیکی سیستم میباشد. درصوت مشکل تخنیکی، ما آنرا حل میسازیم.",
    CONFIRM: "تایید",
    UNIVERSITY_ADMINISTRATION: "اداره پوهنتون",
    TYPE_HERE: "اینجا بنویسید",
    TYPE_COMMENT_HERE: "اینجا نظریه خود را بنویسید",
    STUDENT: "محصل",
    SEARCH: "جستجو",
    YOU: "شما",
    DELETE_MESSAGE: "حذف پیام",
    CHANCE: "چانس",
    ENTER_YOUR_EMAIL_ADDRESS_HERE: "ایمیل آدرس خود را اینجا بنویسید",
    ONLINE_CLASS: "صنف آنلاین",
    JOIN: "اشتراک",
    NOTICE_FROM: "از طرف",
    NOTICE_END_DATE: "تاریخ ختم اطلاعیه",
    UPLOAD_NEW_ASSIGNMENT: "آپلود کارخانگی جدید",
    UPLOAD_NEW_QUIZ: "آپلود امتحان جدید",
    NO_SUBJECTS_IN_CLASS: "مضمون در این صنف درج نگردیده است",
    ASSIGNMENT_SUBMISSION_FORM: "فورم ارسال کارخانگی",
    SUBMITTED_ON: "ارسال شده در",
    NO_ATTEMPT: "بدون تلاش",
    MARK: "نمره",
    INFO_FOR_SUBMISSION:
      "نوت: شما فقط یک بار اجازه ارسال فایل برای کارخانګی را دارید. پس لطف نموده فایل را با دقت کامل آماده و ارسال نماید. بعد از یک بار ارسال کارخانگی فورم برای این کارخانگی قابل دید نمی باشد",
    STATUS: "وضعیت",
    SUBMIT: "ثبت",
    TITLE: "عنوان",
    IS_IDENTITY_HIDDEN: "معلومات شخصی من را محرم نگهدارید",
    COMPLAINTS_HANDLING: "رسیدگی به شکایات امتحانات",
    EXAM_COMMITTEE: "کمیته امتحانات",
    INSERT: "را درج نماید!",
    DETAILS: "جزئیات",
    SUBJECT_BOOK: "عنوان کتاب",
    AUTHOR: "نویسنده",
    SHELF_NUMBER: "شماره الماری / فولدر اصلی",
    SHELF_ROW: "طبقه الماری / فولدر فرعی",
    LIBRARY_TITLE: "عنوان کتاب",
    LANGUAGE: "زبان",
    NUMBER_OF_VOLUMES: "تعداد جلد",
    EDITION: "نسخه",
    PUBLISHER: "ناشر",
    PUBLISH_YEAR: "سال نشر",
    ISBN: "ISBN",
    TRANSLATOR: "مترجم",
    NUMBER_OF_COPIES: "تعداد کاپی",
    REMARKS: "توضیحات",
    FACULTY_NAME: "نام پوهنحی",
    CATEGORY_NAME: "نام دسته بندی"
  },
  PROFILE: {
    GENERAL_INFORMATION: "معلومات عمومی",
    PERSONAL_INFORMATION: "معلومات شخصی",
    IDENTITY_CARD_INFORMATION: "معلومات تذکره هویت",
    ACCOMMODATION_INFORMATION: "معلومات سکونت",
    RELATIVES_INFORMATION: "معلومات اقارب",
    GUARANTOR_INFORMATION: "معلومات تضمین کننده",
    ID: "آی دی نمبر",
    NAME: "اسم",
    LAST_NAME: "تخلص",
    FATHER_NAME: "اسم پدر",
    GRAND_FATHER_NAME: "اسم پدرکلان",
    MOTHER_TONGUE: "زبان مادری",
    GENDER: "جنسیت",
    MARITAL_STATUS: "حالت مدنی",
    PLACE_OF_BIRTH: "محل تولد",
    DATE_OF_BIRTH: "سال تولد",
    JOB: "وظیفه",
    CONTACT_NUMBER: "شماره تماس",
    EMAIL_ADDRESS: "ایمیل آدرس",
    BLOOD_GROUP: "گروپ خون",
    TAZKIRA_ID: "شماره تذکره",
    TAZKIRA_VOLUME: "جلد",
    TAZKIRA_PAGE: "صفحه",
    TAZKIRA_REGISTRATION_NUMBER: "شماره ثبت",
    PERMANENT_RESIDENCE: "سکونت اصلی",
    CURRENT_RESIDENCE: "سکونت فعلی",
    RESIDENTIAL_PROVINCE: "ولایت",
    RESIDENTIAL_DISTRICT: "ولسوالی/ناحیه",
    RESIDENTIAL_VILLAGE: "قریه",
    FATHER_JOB: "وظیفه پدر",
    FATHER_CONTACT_NUMBER: "شماره تماس پدر",
    GUARANTOR_RELATIONSHIP: "قرابت",
    GUARANTOR_JOB_LOCATION: "محل وظیفه",
    BROTHER_NAME: "نام برادر",
    BROTHER_CONTACT_NUMBER: "شماره تماس برادر",
    KANKOR_INFORMATION: "معلومات کانکور",
    KANKOR_TYPE: "نوعیت کانکور",
    KANKOR_PERIOD: "دوره کانکور",
    ACADEMIC_INFORMATION: "معلومات تحصیلی",
    SCHOOL_GRADUATION: "فارغ تحصیل لیسه",
    SCHOOL_GRADUATION_YEAR: "سال فراغت لیسه",
    TWELFTH_INSTITUTION: "بکلوریا (12)",
    FOURTEENTH_INSTITUTION: "فوق بکلوریا (14)",
    BACHELOR_INSTITUTION: "لیسانس (16)",
    INSTITUTE_SPECIALIZATION_FIELD: "رشته اختصاصی",
    INSTITUTE_NAME: "انستیتوت",
    INSTITUTE_MARKS_AVERAGE: "اوسط نمرات انستیتوت",
    INSTITUTE_ADDRESS: "آدرس انستیتوت",
    INSTITUTE_GRADUATION_YEAR: "سال فراغت از انستیتوت",
    INSTITUTION_NAME: "نام نهاد",
    INSTITUTION_FACULTY: "پوهنځی",
    INSTITUTION_DEPARTMENT: "رشته",
    INSTITUTION_ADDRESS: "آدرس نهاد",
    INSTITUTION_GRADUATION_YEAR: "سال فراغت",
    INSTITUTION_MARKS_AVERAGE: "اوسط نهایی",
    STUDENT_FREEZE_INFORMATION: "معلومات تأجیل",
    NO_FREEZE: "تأجیل گرفته نشده",
    EDUCATIONAL_YEAR: "سال تحصیلی",
    EDUCATIONAL_TERM: "دوره سمستر",
    REASON: "علت",
    CURRENT_STATE: "حالت فعلی",
    START_DATE: "تاریخ شروع",
    END_DATE: "تاریخ ختم",
    CONTRACT_TYPE: "نوعیت قرارداد",
    PERMANENT: "دائمی",
    CONTRACTUAL: "بالمقطع",
    WORK_EXPERIENCE: "تجربه تدریس",
    SINGLE: "مجرد",
    MARRIED: "متأهل",
    SCIENTIFIC_RANKING: "رتبه علمی",
    EDUCATION_TYPE: "نویعت تحصیل",
    TAX_NUMBER: "نمبر تشخیصیه (TIN)",
    NO_DATA: "معلومات در این مورد ثبت نگردیده"
  },
  SEND_ERROR_REPORT: {
    MARKS_ERROR_TITLE: 'ارسال شکایت در نمرات مضمون "{subject}"',
    ERROR_DESCRIPTION: "توضیحات شکایت",
    SEND_ERROR: "ارسال شکایت"
  },
  FORUM: {
    TITLE: "انجمن"
  },
  ACTIVITIES: {
    TITLE: "فعالیت ها"
  },
  VALIDATION: {
    INVALID: "{name} درست نمیباشد",
    REQUIRED: "{name} الزامی است",
    MAX_LENGTH: "{name} حداکثر باید {max} باشد",
    MEDIA_TYPE:
      "نوعیت فایل باید MS Word، MS Excel، MS PowerPoint، MP4 Video، AVI Video. WMV Video. FLV Video. MKV Video. MOV Video. 3GP Video. Image ویا PDF باشد",
    ASSIGNMENT_FILE_TYPE:
      "نوعیت فایل باید MS Word، MS Excel، MS PowerPoint، Image ویا PDF باشد",
    MIN_LENGTH: "{name} حداقل باید {min} باشد",
    INVALID_FIELD: "فیلد درست نمیباشد",
    BETWEEN: "باید بین 0 تا باشد 100"
  },
  SCHEDULE: {
    TITLE: "تقسیم اوقات",
    NO_RESULT: "تقسیم اوقات تا حال درج سیستم نه شده است",
    START_TIME: "ساعت شروع",
    END_TIME: "ساعت ختم",
    DAY: "روز",
    WEEK_DAYS: "ایام هفته",
    WEEK_DAY: {
      1: "شنبه",
      2: "یکشنبه",
      3: "دوشنبه",
      4: "سه شنبه",
      5: "چهار شنبه",
      6: "پنجشنبه"
    }
  },
  ONLINE_CLASS: {
    TITLE: "درس مضمون {subject} با استاد {lecturer}"
  },
  SALARIES: {
    TITLE: "راپور معاشات",
    NO_RECORDS: "برای حساب شما تا حال راپور معاشات درج سیستم نگردیده است."
  }
};
